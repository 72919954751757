import React from 'react'
import { ThemeProvider } from '@mui/material'
import { graphql, PageProps } from 'gatsby'
import Layout from '@talentinc/gatsby-theme-ecom/components/Layout'
import { PageDataContextType } from '@talentinc/gatsby-theme-ecom/types/page'
import { FAQPage as ContentfulFAQPage } from '@talentinc/gatsby-theme-ecom/types/faq'
import FAQPage from '@talentinc/gatsby-theme-ecom/components/FAQ/FAQPage'
import SEO from '@talentinc/gatsby-theme-ecom/components/SEO'
import { ZipJobLandingTheme } from '../styles'
import HeaderV2 from '../components/HeaderV2'
import Footer from '../components/Footer'

const FAQTemplate: React.FC<
  PageProps<PageDataContextType & { faqPage: ContentfulFAQPage }>
> = (props) => {
  const { faqPage, ...pageData } = props.data

  return (
    <ThemeProvider theme={ZipJobLandingTheme}>
      <Layout pageData={pageData}>
        <SEO landingPage={faqPage} />
        <HeaderV2 />
        <FAQPage faqPage={faqPage} />
        <Footer />
      </Layout>
    </ThemeProvider>
  )
}

export const query = graphql`
  query FAQPageBySlugAndBrand($slug: String!, $brandName: String!) {
    faqPage: contentfulFaqPage(
      slug: { eq: $slug }
      brand: { elemMatch: { name: { eq: $brandName } } }
    ) {
      ...FAQPage
    }
    brand: contentfulBrand(name: { eq: $brandName }) {
      ...Brand
    }
    headerV2: contentfulHeaderV2(
      brand: { elemMatch: { name: { eq: $brandName } } }
    ) {
      ...HeaderV2
    }
    footer: contentfulFooter(brand: { name: { eq: $brandName } }) {
      ...Footer
    }
    locales: allLocale(filter: { language: { eq: "en" } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default FAQTemplate
